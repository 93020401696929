import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
interface PrivacyNotice {
  name: string;
  fileName: string;
  updatedAt?: string;
}

function App() {
  const privacyNoties: PrivacyNotice[] = [
    {
      name: "แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคล สำหรับผู้ถือหุ้น ผู้ถือหุ้นกู้ และตัวแทนของบุคคลดังกล่าว",
      fileName: "/files/01.Privacy-Notice-Rev.01.pdf",
    },
    {
      name: "แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคล สำหรับกรรมการ ผู้บริหารระดับสูงสุด และบุคคลที่อาจเป็นกรรมการ หรือผู้บริหารระดับสูงสุด ของซิซซา",
      fileName: "/files/02.Privacy-Notice-Rev.01.pdf",
    },
    {
      name: "แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคล สำหรับลูกค้า คู่ค้า ผู้มีความสัมพันธ์ทางธุรกิจ และผู้ปฏิบัติงานในกลุ่ม ซิซซา",
      fileName: "/files/03.Privacy-Notice-Rev.01.pdf",
    },
    {
      name: "แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคล สำหรับผู้ใช้งานเว็บไซต์ของ ซิซซา",
      fileName: "/files/04.Privacy-Notice-Rev.01.pdf",
    },
    {
      name: "แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคล สำหรับผู้มาติดต่อ ผู้ขอเข้าอาคาร หรือสถานที่ปฏิบัติงาน",
      fileName: "/files/05.Privacy-Notice-Rev.01.pdf",
    },
    {
      name: "แบบแจ้งเกี่ยวกับข้อมูลส่วนบุคคล สำหรับผู้เข้าร่วมการอบรม สัมมนา การศึกษาดูงาน กิจกรรมชุมชนสัมพันธ์ หรือกิจกรรมอื่น ๆ",
      fileName: "/files/06.Privacy-Notice-Rev.01.pdf",
    },
  ];

  return (
    <div className="antialiased text-gray-900 min-h-screen flex flex-col">
      <Header />
      <div className="container px-5 py-24 mx-auto bg-white flex-grow">
        <section className="text-gray-600 body-font">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              หนังสือแจ้งการคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice)
            </h1>
            <p className="lg:w-2/3 mx-auto text-base"></p>
          </div>
        </section>
        <div className="grid lg:grid-cols-3 gap-4 lg:gap-6">
          {privacyNoties &&
            privacyNoties.map((privacyNotie) => (
              <div
                key={privacyNotie.fileName}
                className="border-gray-200 border p-4 rounded-lg text-gray-900 hover:text-primary hover:border-primary"
              >
                <a href={privacyNotie.fileName}>
                  <div className="flex items-top">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 lg:h-12 w-8 lg:w-12 mr-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div>
                      <h2 className="text-sm md:text-base font-medium mb-3">
                        {privacyNotie.name}
                      </h2>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
