import React from "react";

type Props = {};

const Footer = (props: Props) => {
  return (
    <footer>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-500 text-sm text-center sm:text-left">
            © 2022 CISSA Group Co., Ltd.
          </p>
          <span className="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">
            193/143 ชั้น 35 โซน ดี อาคาร เลครัชดา คลองเตย, กรุงเทพฯ 10110
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
